// This is the custom style implementation for FWG and is used as a template by the WhiteLabelPreProcessor
@use '@angular/material' as mat;

$lbp-red: (
  50 : #fceae0,
  100 : #f8cab3,
  200 : #f4a780,
  300 : #f0844d,
  400 : #ec6926,
  500 : #e94f00,
  600 : #e64800,
  700 : #e33f00,
  800 : #df3600,
  900 : #d92600,
  A100 : #ffffff,
  A200 : #ffd3ce,
  A400 : #ffa69b,
  A700 : #ff8f81,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$lbp-slategray: (
  50: #f1f2f5,
  100: #dddee7,
  200: #c6c8d7,
  300: #afb1c6,
  400: #9da1ba,
  500: #8c90ae,
  600: #8488a7,
  700: #797d9d,
  800: #6f7394,
  900: #5c6184,
  A100: #f1f3ff,
  A200: #bec6ff,
  A400: #8b99ff,
  A700: #7283ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$lbp-green: (
  50: #f4f7f2,
  100: #e4ecde,
  200: #d3dfc9,
  300: #c1d2b3,
  400: #b3c8a2,
  500: #A6C58C,
  600: #9eb88a,
  700: #95af7f,
  800: #8ba775,
  900: #7b9963,
  A100: #ffffff,
  A200: #e9ffd8,
  A400: #ccffa5,
  A700: #bdff8b,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$lbp-primary: mat.define-palette($lbp-red);
$lbp-accent: mat.define-palette($lbp-green);
$lbp-warn: mat.define-palette($lbp-red);

$lbp-theme: mat.define-light-theme($lbp-primary, $lbp-accent, $lbp-warn);

@include mat.all-component-themes($lbp-theme);

$primary: mat.get-color-from-palette($lbp-primary);
$accent: mat.get-color-from-palette($lbp-accent);
$warn: mat.get-color-from-palette($lbp-warn);

$background: #fefefe;
$card: map-get(map-get($lbp-theme, background), card);
$border: mat.get-color-from-palette(map-get($lbp-theme, foreground), divider);
$secondary-text: mat.get-color-from-palette(map-get($lbp-theme, foreground), secondary-text);
