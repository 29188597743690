// This is the custom style implementation for FWG and is used as a template by the WhiteLabelPreProcessor
@use "@angular/material" as mat;
@import "/node_modules/@angular/material/_theming";

$family: "mic-32-new-rounded-web", sans-serif;

$lbp-typography: mat.define-typography-config(
  $font-family: $family,
);
@include mat.core($lbp-typography);

html,
body {
  font-family: $family, sans-serif;
}

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$font-size-regular: 16px;
$card-content-line-height: 20px;

.font-weight-light {
  font-weight: $font-weight-light;
}

.font-weight-regular {
  font-weight: $font-weight-regular;
}

.font-weight-medium {
  font-weight: $font-weight-medium;
}

.font-weight-bold {
  font-weight: $font-weight-bold;
}

@mixin font-weight-light() {
  font-weight: $font-weight-light;
}

@mixin font-weight-regular() {
  font-weight: $font-weight-regular;
}

@mixin font-weight-medium() {
  font-weight: $font-weight-medium;
}

@mixin font-weight-bold() {
  font-weight: $font-weight-bold;
}

@mixin no-margin() {
  margin: 0;
}

// todo
$typography-light: #ffffff;

h1 {
  font-size: 42px;
  line-height: 48px;
  @include font-weight-bold();
  @include no-margin();
  color: $pallete-typography-primary-100;
  text-wrap: balance;
}

h2 {
  font-size: 32px;
  line-height: 36px;
  @include font-weight-bold();
  @include no-margin();
  color: $pallete-typography-primary-100;
  text-wrap: balance;
}

h3 {
  font-size: 24px;
  line-height: 28px;
  @include font-weight-bold();
  @include no-margin();
  color: $pallete-typography-primary-100;
  text-wrap: balance;
}

h4 {
  font-size: 18px;
  line-height: 20px;
  @include font-weight-bold();
  @include no-margin();
  color: $pallete-typography-primary-100;
  text-wrap: balance;
}

h5 {
  font-size: 16px;
  line-height: 18px;
  @include font-weight-bold();
  @include no-margin();
  color: $pallete-typography-primary-100;
  text-wrap: balance;
}

h6 {
  font-size: 14px;
  line-height: 16px;
  @include font-weight-bold();
  @include no-margin();
  color: $pallete-typography-primary-100;
  text-wrap: balance;
}

p {
  font-size: 18px;
  line-height: 24px;
  @include font-weight-light();
  color: $pallete-typography-primary-100;
}

li {
  @include font-weight-light();
}

@media screen and (max-width: 768px) {
  p, li {
    font-size: $font-size-regular;
    line-height: 20px;
  }

  h1 {
    font-size: 32px;
    line-height: 36px;
  }

  h2 {
    font-size: 24px;
    line-height: 24px;
  }

  h3 {
    font-size: 18px;
    line-height: 20px;
  }

  h4 {
    font-size: $font-size-regular;
    line-height: 18px;
  }

  h5 {
    font-size: 14px;
    line-height: $font-size-regular;
  }

  h6 {
    // todo
  }
}

button {
  font-family: "mic-32-new-rounded-web", sans-serif;
  font-size: 14px;
}
