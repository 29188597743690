@font-face {
  font-family: "mic-32-new-rounded-web";
  src: local('mic-32-new-rounded-web'), url("../assets/fonts/mic-new-web-700.woff2") format("woff2"),
  url("../assets/fonts/mic-new-web-700.woff") format("woff"),
  url("../assets/fonts/mic-new-web-700.ttf") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "mic-32-new-rounded-web";
  src: local('mic-32-new-rounded-web'), url("../assets/fonts/mic-new-web-700-italic.woff2") format("woff2"),
  url("../assets/fonts/mic-new-web-700-italic.woff") format("woff"),
  url("../assets/fonts/mic-new-web-700-italic.ttf") format("opentype");
  font-display: swap;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "mic-32-new-rounded-web";
  src: local('mic-32-new-rounded-web'), url("../assets/fonts/mic-new-web-300.woff2") format("woff2"),
  url("../assets/fonts/mic-new-web-300.woff") format("woff"),
  url("../assets/fonts/mic-new-web-300.ttf") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "mic-32-new-rounded-web";
  src: local('mic-32-new-rounded-web'), url("../assets/fonts/mic-new-web-300-italic.woff2") format("woff2"),
  url("../assets/fonts/mic-new-web-300-italic.woff") format("woff"),
  url("../assets/fonts/mic-new-web-300-italic.ttf") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "mic-32-new-rounded-web";
  src: local('mic-32-new-rounded-web'), url("../assets/fonts/mic-new-web-600.woff2") format("woff2"),
  url("../assets/fonts/mic-new-web-600.woff") format("woff"),
  url("../assets/fonts/mic-new-web-600.ttf") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "mic-32-new-rounded-web";
  src: local('mic-32-new-rounded-web'), url("../assets/fonts/mic-new-web-600-italic.woff2") format("woff2"),
  url("../assets/fonts/mic-new-web-600-italic.woff") format("woff"),
  url("../assets/fonts/mic-new-web-600-italic.ttf") format("opentype");
  font-display: swap;
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: "mic-32-new-rounded-web";
  src: local('mic-32-new-rounded-web'), url("../assets/fonts/mic-new-web-400.woff2") format("woff2"),
  url("../assets/fonts/mic-new-web-400.woff") format("woff"),
  url("../assets/fonts/mic-new-web-400.ttf") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "mic-32-new-rounded-web";
  src: local('mic-32-new-rounded-web'), url("../assets/fonts/mic-new-web-400-italic.woff2") format("woff2"),
  url("../assets/fonts/mic-new-web-400-italic.woff") format("woff"),
  url("../assets/fonts/mic-new-web-400-italic.ttf") format("opentype");
  font-display: swap;
  font-style: italic;
  font-weight: 400;
}

.tk-mic-32-new-web {
  font-family: "mic-32-new-web", sans-serif;
}

.tk-mic-32-new-rounded-web {
  font-family: "mic-32-new-rounded-web", sans-serif;
}
