@use "@angular/material/theming" as mat;
@import "colors.partial";
@import "material-theme.partial";
@import "spatial.partial";
@import "typography.partial";
@import "material-icons/iconfont/material-icons.css";
@import "typekit-style";

html,
body {
  height: 100%;
  width: 100%;
}

// * {
//   outline: 1px solid red !important;
// }

.mat-app-background {
  background-color: $background !important;
}

body {
  margin: 0 auto;
  line-height: 1.5;
}

img {
  display: block;
  max-height: 100%;
}

[hidden] {
  opacity: 0 !important;
}

.mat-button .mat-icon {
  position: relative;
  top: -1px;
}

a {
  color: $primary;
  // font-weight: bold;

  &:link {
    text-decoration: none;
    color: $primary;
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 4px;
    color: $primary;
  }
}

.mat-vertical-stepper-header {
  padding-left: 0 !important;
}

.mat-dialog-content {
  overflow: initial !important;
}

.mat-vertical-content-container {
  margin-left: $medium-margin !important;
}

.mat-dialog-container {
  border-radius: 0 !important;
  padding: 0 !important;
}

mat-vertical-stepper {
  background-color: $background !important;
}

.shadow {
  color: $secondary-text;
}

// a {
//   color: $primary;
// }

@mixin primary-typography-100() {

  h1,
  h2,
  h3,
  h4 {
    color: $pallete-typography-primary-100;
  }
}

@mixin primary-typography-60() {

  h1,
  h2,
  h3,
  h4 {
    color: $pallete-typography-primary-60;
  }
}

@mixin primary-typography-30() {

  h1,
  h2,
  h3,
  h4 {
    color: $pallete-typography-primary-30;
  }
}

// a:link {
//   text-decoration: none;
// }

// a:visited {
//   text-decoration: none;
// }

@mixin hover($backgroundColor) {
  &:hover {
    transition: all 0.5s;
    color: $backgroundColor;
  }
}

// a:hover {
//   text-decoration: none;
//   @include hover(darken($pallete-primary-100, 20%));
// }

// a:active {
//   text-decoration: none;
// }

mat-progress-bar .mat-progress-bar-buffer {
  background: #cecece;
}

.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background: #cecece;
}

.function-compare-left .mat-progress-bar-fill::after {
  background-color: $function-compare-color-left !important;
}

.function-compare-right .mat-progress-bar-fill::after {
  background-color: $function-compare-color-right !important;
}

.mat-header-cell {
  color: black !important;
  font-weight: 900 !important;
  font-family: $family !important;
}

.mat-footer-cell {
  font-weight: 900 !important;
  font-family: $family !important;
}

.pre-wrap {
  white-space: pre-wrap;
}

iframe {
  width: 100%;
  height: 100%;
}

ul,
ol,
li {
  color: $pallete-typography-primary-100;
}